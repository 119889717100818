* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.home,
.about,
.puppies,
.dogs,
.prev {
    display: flex;
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
}

.home {
    background-image: url('/public/images/homepage.webp');
    
}

.about {
    background-image: url('/public/images/aboutpage.webp');
}

.prev {
    background-image: url('/public/images/puppies.jpg');
    height: 80vh;
    background-position: 30%;
    color: #000000;
}

.welcome {
    background-color: rgba(26, 23, 23, 0.90);
    padding: 30px;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 62.5vh;
    height: fit-content;
    text-align: center;
    display: block;
}

.construction {
    color: rgb(252, 247, 0);
    font-size: 25px;
}

.home .construction {
    margin-top: 10px;
}

.text-container {
    display: flex;
    flex-direction: column;
    padding: 2rem 4rem 1rem 4rem;
    align-items: center;
    text-align: center;
}

.rubric-1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.rubric-2 {
    font-size: 20px;
    margin-bottom: 20px;
}

.top-space {
    margin-top: 20px;
    margin-bottom: 0px;
}

.text-container-left {
    align-items: start;
    text-align: left;
}

@media screen and (max-width: 960px) {
    .home {
        background-image: url('/public/images/homepage.webp');
        background-position: 30% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        color: #fff;
    }
}