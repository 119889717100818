.slideshow {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1400px;
    max-height: 1200px;
}

.slide-image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.slide-loading-img {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.slide-container {
    position: relative;
    margin: 0px 20px 20px 20px;
}

.slide-loading-img-container {
    width: 100%;
    height: 100%;
}

.slide-next, .slide-prev {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    color: white;
    padding: 16px;
    font-size: 30px;
}

.slide-next {
    right: 0;
}

.slide-info {
    color: rgb(0, 0, 0);
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.slide-fade {
    animation-name: fade;
    animation-duration: 1.5s;
}

@keyframes fade {
    from {opacity: 0.4;}
    to {opacity: 1;}
}
