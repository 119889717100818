.cards {
  padding: 0 0 20px 0;
}

.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-wrap {
  display: flex;
}

.cards-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  justify-content: center;
  list-style: none;
}

.card-item {
  margin: 20px 20px 20px 20px;
}

.card-item-container {
  max-width: 750px;
}

.card-item-image {
  max-width: 100%;
  max-height: 100%;
}

.card-info{
  margin: 0 10px 0 10px;
}

.card-info-header {
  font-size: 24px;
  font-weight: bold;
}

.card-subheader {
  font-size: 18px;
  font-weight: bold;
}

.card-bullet-list {
  padding-left: 35px;
  margin-bottom: 5px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}